.skeleton-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skeleton-item {
    height: 20px;
    background-color: #ddd;
    border-radius: 4px;
    background-image: linear-gradient(to right,
            #eeeeee 0%,
            #dddddd 20%,
            #eeeeee 40%,
            #eeeeee 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    overflow: hidden;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}